.recipe-list__filters {
  @media @m {
    grid-column: 1/3;
    grid-row: 1/8;
  }
}

.recipe-list__search {
  margin-bottom: @spacing-double;
  padding: @spacing-half 2rem @spacing-half @spacing-half;
  width: 100%;
  background: url('/assets/icons/magnifier.svg') @white no-repeat;
  background-size: 1rem;
  background-position: right @spacing-half center;
  border: none;
  font-size: @font-size-small;
  color: @black;
  appearance: none;
}

.recipe-list__items {
  margin-bottom: @spacing-double;

  @media @m {
    margin-bottom: 0;
  }

  @media @l {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: start;
  }
}

.recipe-list__recipes {
  @media @m {
    grid-column: 4/11;
    grid-row: 1/8;
  }
}
.recipe-list__recipe {
  margin-bottom: @spacing-default;

  @media @m {
    margin-bottom: @spacing-double;
  }

  @media @l {
    width: calc(50% - @spacing-default);
  }
}

.recipe-list__recipe a {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 57px);
  padding-bottom: 0;
  background: none;
}

.recipe-list__image {
  overflow: hidden;
  grid-column: 1/2;
  grid-row: 1/3;
}

.recipe-list__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform .2s;
}

.recipe-list__title {
  grid-column: 1/2;
  grid-row: 3/4;
  margin-bottom: 0;
  padding: @spacing-half (@spacing-half*1.5);
  background-color: @brand-color-light;
  font-size: @font-size-bigger;
  font-weight: normal;
  transition: background-color .2s;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

.recipe-list__recipe a:hover .recipe-list__title,
.recipe-list__recipe a:focus .recipe-list__title {
  background-color: @brand-color;
  color: @black;
}

.recipe-list__recipe a:hover .recipe-list__image img,
.recipe-list__recipe a:focus .recipe-list__image img {
  transform: scale(1.03);

  @media (prefers-reduced-motion: reduce) {
    transform: none;
  }
}

.recipe-list__meta {
  display: none;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  max-width: 300px;
  list-style: none;
}

.pagination .page {
  display: inline-block;
  margin: 0 @spacing-half;
  padding: 5px 12px;
  border: 1px solid @brand-color;
  background: none;
}

.pagination .active .page {
  background: @brand-color;
  color: @white;
}

.pagination .active .page:hover,
.pagination .active .page:focus {
  background: transparent;
  color: @brand-color;
}