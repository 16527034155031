.filter-block {
  margin-bottom: @spacing-double;
}

.filter-block__title {
  margin-bottom: @spacing-default;
}

.filter-block__toggle {
  position: relative;
  margin-bottom: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  border: none;
  appearance: none;
  text-align: left;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus {
    color: @brand-color;
  }
}

.filter-block__toggle::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1rem;
  height: 1rem;
  background: url('/assets/icons/arrow.svg') transparent no-repeat;
  background-position: top 6px right;
  background-size: 1rem;
  transition: transform .3s;

  .filter-block--open & {
    background-position: top 3px right;
    transform: rotate(180deg);
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

.filter-block__toggle:hover::after,
.filter-block__toggle:focus::after {
  background-image: url('/assets/icons/arrow--brand-color.svg');
}

.filter-block__filters {
  display: none;

  .filter-block--open & {
    display: block;
  }
}

.filter-block__label {
  display: block;
  margin-bottom: @spacing-half;
  padding: (@spacing-half/2) (@spacing-half/2) (@spacing-half/2) (@spacing-default*1.5);
  width: 100%;
  border: 1px solid @grey;
  background-color: transparent;
  font-size: @font-size-small;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color .2s;

  .filter-block__input:checked ~ & {
    background: url('/assets/icons/checkmark.svg') @brand-color-light no-repeat;
    background-position: left (@spacing-default*.75) center;
    background-size: .5rem;
    border-color: @brand-color-light;
    font-weight: bold;
    color: @black;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

.filter-block__label:hover,
.filter-block__label:focus,
.filter-block__input:checked:hover ~ .filter-block__label,
.filter-block__input:checked:focus ~ .filter-block__label {
  background-color: @brand-color;
  border-color: @brand-color;
}
