.header {
  display: flex;
  align-items: center;
  height: 50px;

  @media @m {
    height: 87px;
  }
}

.header__link {
  background: none;
}

.header__title {
  position: relative;
  margin-bottom: 0;
}

.header__title::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -4px;
  right: -11px;
  width: 100%;
  height: 3px;
  background-color: @black;
  transform: translateX(0);
  transition: transform .2s;
}

.header__link:hover,
.header__link:focus {
  background: none;
}

.header__link:hover .header__title::after,
.header__link:focus .header__title::after {
  background-color: @brand-color;
  transform: translateX(-11px);

  @media (prefers-reduced-motion: reduce) {
    transform: none;
  }
}
