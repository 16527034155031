.ingredients-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  @media @m {
    align-content: start;
    grid-column: 1 / 5;
    grid-row: 4 / 9;
    padding-right: @spacing-double;
  }
}

.ingredients-list__ingredients {
  grid-column: 1 / 5;
  font-size: @font-size-smaller;
  line-height: 1.4;
}

.ingredients-list__ingredient {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: @spacing-default;
}

.ingredients-list__amount {
  grid-column: 1 / 2;
  padding-right: @spacing-half;
  text-align: right;
}

.ingredients-list__name {
  grid-column: 2 / 5;
  padding-left: @spacing-half;
}
