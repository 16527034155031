.meta-info {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 70px);
  margin-bottom: @spacing-double;

  @media @m {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 87px);
    grid-column: 5 / 11;
    grid-row: 1 / 6;
    margin-bottom: 0;
  }
}

.meta-info__image {
  grid-column: 1 / 5;
  grid-row: 1 / 3;

  @media @m {
    grid-column: 1 / 7;
    grid-row: 1 / 5;
  }
}

.meta-info__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.meta-info__meta,
.meta-info__source,
.meta-info__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 @spacing-half;
  font-size: @font-size-small;
  line-height: 1.5;
  text-transform: uppercase;
  text-align: center;
}

.meta-info__meta {
  grid-column: 1/3;
  grid-row: 4/5;

  @media @m {
    grid-column: 1 / 3;
    grid-row: 5/6;
  }
}

.meta-info__source {
  grid-column: 1 / 5;
  grid-row: 3 / 4;
  background-color: @brand-color-light;
  color: @white;

  @media @m {
    grid-column: 5 / 7;
    grid-row: 5 / 6;
  }
}

.meta-info__source-title {
  display: block;
  font-size: @font-size-smaller;
  font-weight: bold;
}

.meta-info__source a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 -@spacing-half;
  padding: @spacing-half;
  height: 100%;
  background: none;
  color: @white;
  transition: background-color .2s;

  &:hover,
  &:focus {
    background-color: @brand-color;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

.meta-info__source a:hover .meta-info__source-title,
.meta-info__source a:focus .meta-info__source-title {
  background: transparent linear-gradient(to bottom, transparent 93%, @white 100%, @white 100%);
}

.meta-info__content {
  grid-column: 3/5;
  grid-row: 4/5;
  margin-bottom: 0;
  padding: @spacing-half;
  list-style: none;

  @media @m {
    grid-column: 3 / 5;
    grid-row: 5 / 6;
  }
}

.meta-info__meta p,
.meta-info__content p {
  margin: 0;
}