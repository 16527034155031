/* COLORS */
/* SPACING */
/* SIZING */
/* TYPOGRAPHY */
*,
*::before,
*::after {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f0efec;
}
@media (min-width: 600px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(6, 87px);
  }
}
.content {
  margin: 0 auto;
  padding: 0 0.5rem 2rem 0.5rem;
  max-width: 960px;
}
@media (min-width: 600px) {
  .content {
    padding: 0 1rem 2rem 1rem;
  }
}
.a11y-sr-only {
  overflow: hidden;
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
  clip: rect(0 0 0 0);
}
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/open-sans-regular.woff2') format('woff2'), url('/assets/fonts/open-sans-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/open-sans-bold.woff2') format('woff2'), url('/assets/fonts/open-sans-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Gentium Basic';
  src: url('/assets/fonts/gentium-basic-bold.woff2') format('woff2'), url('/assets/fonts/gentium-basic-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
body {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  color: #333333;
  line-height: 1.7;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  margin: 0 0 1.5rem 0;
  font-family: 'Gentium Basic', serif;
  font-weight: bold;
  line-height: 1.1;
}
h1,
.h1 {
  font-size: 1.5rem;
}
h2,
.h2 {
  margin-bottom: 0;
  font-size: 1.9rem;
  color: #be748d;
}
@media (min-width: 600px) {
  h2,
  .h2 {
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  h2,
  .h2 {
    font-size: 2.375rem;
  }
}
h3,
.h3 {
  font-size: 1.125rem;
}
h4,
.h4 {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-size: 1rem;
}
a {
  padding-bottom: 0.15rem;
  background: transparent linear-gradient(to bottom, transparent 93%, #333333 100%, #333333 100%);
  text-decoration: none;
  color: #333333;
}
a:hover,
a:focus {
  color: #be748d;
  background: transparent linear-gradient(to bottom, transparent 93%, #be748d 100%, #be748d 100%);
}
ul,
ol {
  margin: 0 0 1.5rem 0;
}
.flat-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 600px) {
  .cooking-steps {
    grid-column: 5 / 10;
    grid-row: 7 / 8;
  }
}
.cooking-steps__step {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.cooking-steps__step:not(:last-child) {
  padding-bottom: 2rem;
}
@media (min-width: 600px) {
  .cooking-steps__step {
    grid-template-columns: repeat(5, 1fr);
  }
}
.cooking-steps__step h4 {
  grid-column: 1 / 2;
  padding-right: 0.5rem;
  font-family: 'Gentium Basic', serif;
  text-align: right;
  line-height: 1.7;
  color: #be748d;
}
.cooking-steps__step div {
  grid-column: 2 / 5;
  padding-left: 0.5rem;
}
@media (min-width: 600px) {
  .cooking-steps__step div {
    grid-column: 2 / 6;
  }
}
.cooking-steps__step p {
  margin: 0;
}
.filter-block {
  margin-bottom: 2rem;
}
.filter-block__title {
  margin-bottom: 1rem;
}
.filter-block__toggle {
  position: relative;
  margin-bottom: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  border: none;
  appearance: none;
  text-align: left;
  cursor: pointer;
  outline: none;
}
.filter-block__toggle:hover,
.filter-block__toggle:focus {
  color: #be748d;
}
.filter-block__toggle::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1rem;
  height: 1rem;
  background: url('/assets/icons/arrow.svg') transparent no-repeat;
  background-position: top 6px right;
  background-size: 1rem;
  transition: transform 0.3s;
}
.filter-block--open .filter-block__toggle::after {
  background-position: top 3px right;
  transform: rotate(180deg);
}
@media (prefers-reduced-motion: reduce) {
  .filter-block__toggle::after {
    transition: none;
  }
}
.filter-block__toggle:hover::after,
.filter-block__toggle:focus::after {
  background-image: url('/assets/icons/arrow--brand-color.svg');
}
.filter-block__filters {
  display: none;
}
.filter-block--open .filter-block__filters {
  display: block;
}
.filter-block__label {
  display: block;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.25rem 0.25rem 1.5rem;
  width: 100%;
  border: 1px solid #808080;
  background-color: transparent;
  font-size: 0.75rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.2s;
}
.filter-block__input:checked ~ .filter-block__label {
  background: url('/assets/icons/checkmark.svg') #d9b5bf no-repeat;
  background-position: left 0.75rem center;
  background-size: 0.5rem;
  border-color: #d9b5bf;
  font-weight: bold;
  color: #333333;
}
@media (prefers-reduced-motion: reduce) {
  .filter-block__label {
    transition: none;
  }
}
.filter-block__label:hover,
.filter-block__label:focus,
.filter-block__input:checked:hover ~ .filter-block__label,
.filter-block__input:checked:focus ~ .filter-block__label {
  background-color: #be748d;
  border-color: #be748d;
}
.header {
  display: flex;
  align-items: center;
  height: 50px;
}
@media (min-width: 600px) {
  .header {
    height: 87px;
  }
}
.header__link {
  background: none;
}
.header__title {
  position: relative;
  margin-bottom: 0;
}
.header__title::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -4px;
  right: -11px;
  width: 100%;
  height: 3px;
  background-color: #333333;
  transform: translateX(0);
  transition: transform 0.2s;
}
.header__link:hover,
.header__link:focus {
  background: none;
}
.header__link:hover .header__title::after,
.header__link:focus .header__title::after {
  background-color: #be748d;
  transform: translateX(-11px);
}
@media (prefers-reduced-motion: reduce) {
  .header__link:hover .header__title::after,
  .header__link:focus .header__title::after {
    transform: none;
  }
}
.ingredients-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media (min-width: 600px) {
  .ingredients-list {
    align-content: start;
    grid-column: 1 / 5;
    grid-row: 4 / 9;
    padding-right: 2rem;
  }
}
.ingredients-list__ingredients {
  grid-column: 1 / 5;
  font-size: 0.875rem;
  line-height: 1.4;
}
.ingredients-list__ingredient {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 1rem;
}
.ingredients-list__amount {
  grid-column: 1 / 2;
  padding-right: 0.5rem;
  text-align: right;
}
.ingredients-list__name {
  grid-column: 2 / 5;
  padding-left: 0.5rem;
}
.meta-info {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 70px);
  margin-bottom: 2rem;
}
@media (min-width: 600px) {
  .meta-info {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 87px);
    grid-column: 5 / 11;
    grid-row: 1 / 6;
    margin-bottom: 0;
  }
}
.meta-info__image {
  grid-column: 1 / 5;
  grid-row: 1 / 3;
}
@media (min-width: 600px) {
  .meta-info__image {
    grid-column: 1 / 7;
    grid-row: 1 / 5;
  }
}
.meta-info__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.meta-info__meta,
.meta-info__source,
.meta-info__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  text-transform: uppercase;
  text-align: center;
}
.meta-info__meta {
  grid-column: 1/3;
  grid-row: 4/5;
}
@media (min-width: 600px) {
  .meta-info__meta {
    grid-column: 1 / 3;
    grid-row: 5/6;
  }
}
.meta-info__source {
  grid-column: 1 / 5;
  grid-row: 3 / 4;
  background-color: #d9b5bf;
  color: #ffffff;
}
@media (min-width: 600px) {
  .meta-info__source {
    grid-column: 5 / 7;
    grid-row: 5 / 6;
  }
}
.meta-info__source-title {
  display: block;
  font-size: 0.875rem;
  font-weight: bold;
}
.meta-info__source a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 -0.5rem;
  padding: 0.5rem;
  height: 100%;
  background: none;
  color: #ffffff;
  transition: background-color 0.2s;
}
.meta-info__source a:hover,
.meta-info__source a:focus {
  background-color: #be748d;
}
@media (prefers-reduced-motion: reduce) {
  .meta-info__source a {
    transition: none;
  }
}
.meta-info__source a:hover .meta-info__source-title,
.meta-info__source a:focus .meta-info__source-title {
  background: transparent linear-gradient(to bottom, transparent 93%, #ffffff 100%, #ffffff 100%);
}
.meta-info__content {
  grid-column: 3/5;
  grid-row: 4/5;
  margin-bottom: 0;
  padding: 0.5rem;
  list-style: none;
}
@media (min-width: 600px) {
  .meta-info__content {
    grid-column: 3 / 5;
    grid-row: 5 / 6;
  }
}
.meta-info__meta p,
.meta-info__content p {
  margin: 0;
}
@media (min-width: 600px) {
  .recipe-list__filters {
    grid-column: 1/3;
    grid-row: 1/8;
  }
}
.recipe-list__search {
  margin-bottom: 2rem;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  width: 100%;
  background: url('/assets/icons/magnifier.svg') #ffffff no-repeat;
  background-size: 1rem;
  background-position: right 0.5rem center;
  border: none;
  font-size: 0.75rem;
  color: #333333;
  appearance: none;
}
.recipe-list__items {
  margin-bottom: 2rem;
}
@media (min-width: 600px) {
  .recipe-list__items {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .recipe-list__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: start;
  }
}
@media (min-width: 600px) {
  .recipe-list__recipes {
    grid-column: 4/11;
    grid-row: 1/8;
  }
}
.recipe-list__recipe {
  margin-bottom: 1rem;
}
@media (min-width: 600px) {
  .recipe-list__recipe {
    margin-bottom: 2rem;
  }
}
@media (min-width: 768px) {
  .recipe-list__recipe {
    width: calc(50% - 1rem);
  }
}
.recipe-list__recipe a {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 57px);
  padding-bottom: 0;
  background: none;
}
.recipe-list__image {
  overflow: hidden;
  grid-column: 1/2;
  grid-row: 1/3;
}
.recipe-list__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s;
}
.recipe-list__title {
  grid-column: 1/2;
  grid-row: 3/4;
  margin-bottom: 0;
  padding: 0.5rem 0.75rem;
  background-color: #d9b5bf;
  font-size: 1.125rem;
  font-weight: normal;
  transition: background-color 0.2s;
}
@media (prefers-reduced-motion: reduce) {
  .recipe-list__title {
    transition: none;
  }
}
.recipe-list__recipe a:hover .recipe-list__title,
.recipe-list__recipe a:focus .recipe-list__title {
  background-color: #be748d;
  color: #333333;
}
.recipe-list__recipe a:hover .recipe-list__image img,
.recipe-list__recipe a:focus .recipe-list__image img {
  transform: scale(1.03);
}
@media (prefers-reduced-motion: reduce) {
  .recipe-list__recipe a:hover .recipe-list__image img,
  .recipe-list__recipe a:focus .recipe-list__image img {
    transform: none;
  }
}
.recipe-list__meta {
  display: none;
}
.pagination {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  max-width: 300px;
  list-style: none;
}
.pagination .page {
  display: inline-block;
  margin: 0 0.5rem;
  padding: 5px 12px;
  border: 1px solid #be748d;
  background: none;
}
.pagination .active .page {
  background: #be748d;
  color: #ffffff;
}
.pagination .active .page:hover,
.pagination .active .page:focus {
  background: transparent;
  color: #be748d;
}
.recipe-title {
  order: 1;
  grid-column: 1 / 7;
  grid-row: 1 / 3;
  display: flex;
  align-items: center;
  padding: 1rem 1rem 1rem 0;
  background: #f0efec;
}
