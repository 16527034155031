@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/open-sans-regular.woff2') format('woff2'),
    url('/assets/fonts/open-sans-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/open-sans-bold.woff2') format('woff2'),
    url('/assets/fonts/open-sans-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gentium Basic';
  src: url('/assets/fonts/gentium-basic-bold.woff2') format('woff2'),
    url('/assets/fonts/gentium-basic-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: @font;
  font-size: @font-size-default;
  color: @black;
  line-height: 1.7;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  margin: 0 0 (@spacing-default*1.5) 0;
  font-family: @font-title;
  font-weight: bold;
  line-height: 1.1;
}

h1, .h1 {
  font-size: @font-size-big;
}

h2, .h2 {
  margin-bottom: 0;
  font-size: (@font-size-header*.8);
  color: @brand-color;

  @media @m {
    margin-bottom: @spacing-default;
  }

  @media @l {
    font-size: @font-size-header;
  }
}

h3, .h3 {
  font-size: @font-size-bigger;
}

h4, .h4 {
  font-family: @font;
  font-size: @font-size-default;
}

a {
  padding-bottom: .15rem;
  background: transparent linear-gradient(to bottom, transparent 93%, @black 100%, @black 100%);
  text-decoration: none;
  color: @black;

  &:hover,
  &:focus {
    color: @brand-color;
    background: transparent linear-gradient(to bottom, transparent 93%, @brand-color 100%, @brand-color 100%);
  }
}

ul, ol {
  margin: 0 0 (@spacing-default*1.5) 0;
}

.flat-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
