.cooking-steps {
  @media @m {
    grid-column: 5 / 10;
    grid-row: 7 / 8;
  }
}

.cooking-steps__step {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  &:not(:last-child) {
    padding-bottom: @spacing-double;
  }

  @media @m {
    grid-template-columns: repeat(5, 1fr);
  }
}

.cooking-steps__step h4 {
  grid-column: 1 / 2;
  padding-right: @spacing-half;
  font-family: @font-title;
  text-align: right;
  line-height: 1.7;
  color: @brand-color;
}

.cooking-steps__step div {
  grid-column: 2 / 5;
  padding-left: @spacing-half;

  @media @m {
    grid-column: 2 / 6;
  }
}

.cooking-steps__step p {
  margin: 0;
}
