*, *::before, *::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: @background-color;
}

.grid {
  @media @m {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(6, 87px);
  }
}

.content {
  margin: 0 auto;
  padding: 0 @spacing-half @spacing-double @spacing-half;
  max-width: @column-width;

  @media @m {
    padding: 0 @spacing-default @spacing-double @spacing-default;
  }
}

.a11y-sr-only {
  overflow: hidden;
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
  clip: rect(0 0 0 0);
}
